import React from "react";
import logo from "../assets/images/logo.png";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { motion } from "framer-motion";
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLocationArrow,
  FaPhone,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-[#b5c4e26f] rouned-t-md p-6 w-full flex flex-col justify-center">
      <div className="grid grid-cols-3 p-10 gap-10">
        <div className="col-span-3 md:col-span-1 flex flex-col gap-5 items-center md:items-start">
          <img src={logo} className="w-48" alt="Vetsee logo" />
          <span className="text-sm text-gray-600 font-semibold text-center md:text-start">
            The first 100% veterinary services platform in Egypt and the Middle
            East.
          </span>
          <div className="socials flex gap-4 items-center">
            <Link to="https://www.facebook.com/vetsee.net" target="_blank">
              <motion.div
                whileHover={{
                  y: -4,
                }}
              >
                <FaFacebook
                  className="cursor-pointer text-cyan-700"
                  size={30}
                />
              </motion.div>
            </Link>
            <Link to="https://www.instagram.com/vetsee.app/" target="_blank">
              <motion.div
                whileHover={{
                  y: -4,
                }}
              >
                <FaInstagram
                  className="cursor-pointer text-cyan-700"
                  size={30}
                />
              </motion.div>
            </Link>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1 flex flex-col gap-5 px-10  items-center md:items-start">
          <div className="flex flex-col md:flex-row gap-2  items-center md:items-start">
            <FaLocationArrow className="text-cyan-700" size={20} />
            <p className="text-md font-bold text-gray-600">
              53 Capital Mall, behind the court House, 2nd Floor, 5th Settlement
              New Cairo, Cairo, Egypt
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <FaPhone className="text-cyan-700" size={15} />
            <p className="text-md font-bold text-gray-600">
              +2 (010) 7 0026446
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-2 items-center">
            <FaEnvelope className="text-cyan-700" size={15} />
            <p className="text-md font-bold text-gray-600"> info@vetsee.net</p>
          </div>
        </div>
        <div className="col-span-3 md:col-span-1 flex flex-col gap-2">
          <p className="text-md font-bold">Downloads</p>
          <Link
            target="_blank"
            to="https://apps.apple.com/eg/app/vetsee/id6502232464"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-[#b5c4e26f] p-5 rounded-full font-bold flex gap-2 text-cyan-700 cursor-pointer"
            >
              <IoIosAppstore className="text-2xl " />
              Download For IOS
            </motion.div>
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=app.vetsee"
            target="_blank"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-[#b5c4e26f] p-5 rounded-full font-bold flex gap-2 text-cyan-700 cursor-pointer"
            >
              <IoLogoGooglePlaystore className="text-2xl" />
              Download For Android
            </motion.div>
          </Link>
        </div>
      </div>
      <hr />
      <div className="w-full flex justify-center mt-2">
        <small className="font-bold">
          &#169; Vetsee 2024 - All Rights Reserved
        </small>
      </div>
    </div>
  );
}

export default Footer;
