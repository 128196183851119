import React from "react";
import logo from "../assets/images/logo.png";
import hero from "../assets/images/hero.png";
import { IoIosAppstore } from "react-icons/io";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <div id="hero" className="grid grid-cols-2 min-h-[600px] p-10 gap-10">
      <div className="col-span-2 md:col-span-1 flex flex-col flex-1">
        <div className="p-5 bg-[#b5c4e26f] rounded-full flex justify-center md:justify-start">
          <img src={logo} className="w-[200px]" alt="Vetsee Logo" />
        </div>
        <div className="flex flex-col flex-1 gap-2 justify-center mt-10 md:mt-0">
          <div className="flex flex-col gap-2">
            <span className="text-3xl md:text-6xl font-[900] text-orange-500">
              Pawsome Care
            </span>
            <span className="text-3xl md:text-6xl font-[900] rounded-md">
              Your Trusted Pet Health Companion
            </span>
            <span className="text-xl md:text-3xl text-gray-700 font-medium">
              Revolutionizing Veterinary Care for Your Furry Friends
            </span>
            <span className="text-md md:text-2xl text-gray-500">
              Experience hassle-free pet care with Pawsome Care. Connect with
              expert vets, track your pet's health, and receive personalized
              care reminders. Simplify your pet's well-being with our innovative
              app.
            </span>
          </div>
        </div>
        <div className="flex flex-col md:flex-row mt-6 md:mt-0 gap-5">
          <Link
            target="_blank"
            to="https://apps.apple.com/eg/app/vetsee/id6502232464"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-[#b5c4e26f] p-5 rounded-full font-bold flex gap-2 text-cyan-700 cursor-pointer"
            >
              <IoIosAppstore className="text-2xl " />
              Download For IOS
            </motion.div>
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=app.vetsee"
            target="_blank"
          >
            <motion.div
              whileHover={{
                y: -5,
              }}
              whileTap={{
                scale: 1.05,
              }}
              className="bg-[#b5c4e26f] p-5 rounded-full font-bold flex gap-2 text-cyan-700 cursor-pointer"
            >
              <IoLogoGooglePlaystore className="text-2xl" />
              Download For Android
            </motion.div>
          </Link>
        </div>
      </div>
      <div className="col-span-2 md:col-span-1 rounded-xl overflow-hidden bg-black">
        <img className="object-fit w-full" src={hero} alt="hero" />
      </div>
    </div>
  );
}

export default Hero;
