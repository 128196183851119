import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { FaUserDoctor } from "react-icons/fa6";
import { PiBandaidsDuotone, PiSparkle } from "react-icons/pi";
import { useInView } from "react-intersection-observer";

function Features() {
  const controls = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  const [ref1, inView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  const [ref3, inView3] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  useEffect(() => {
    if (inView2) {
      controls2.start("visible");
    } else {
      controls2.start("hidden");
    }
  }, [inView2, controls2]);

  useEffect(() => {
    if (inView3) {
      controls3.start("visible");
    } else {
      controls3.start("hidden");
    }
  }, [inView3, controls3]);

  const variant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="grid grid-cols-3 ">
      <motion.div
        initial={"hidden"}
        animate={controls}
        variants={variant}
        ref={ref1}
        className="col-span-3 md:col-span-1 py-20 bg-indigo-400 flex flex-col gap-2 text-start justify-center items-start p-4 text-white"
      >
        <FaUserDoctor className="text-5xl" />
        <h1 className="text-5xl font-bold">Easy Appointment Booking</h1>
        <p className="text-gray-100 text-xl font-light">
          Effortlessly schedule vet appointments at your convenience with our
          intuitive booking system.
        </p>
      </motion.div>
      <motion.div
        initial={"hidden"}
        animate={controls2}
        variants={variant}
        ref={ref2}
        className="col-span-3 md:col-span-1 py-20 bg-blue-400  flex flex-col gap-2 text-start justify-center items-start p-4 text-white"
      >
        <PiBandaidsDuotone className="text-5xl" />
        <h1 className="text-5xl font-bold">Specialized Surgery Services</h1>
        <p className="text-gray-100 text-xl font-light">
          Access top-tier surgical care tailored to your pet's needs through our
          network of skilled surgeons.
        </p>
      </motion.div>
      <motion.div
        initial={"hidden"}
        animate={controls3}
        variants={variant}
        ref={ref3}
        className="col-span-3 md:col-span-1 py-20 bg-pink-400   flex flex-col gap-2 text-start justify-center items-start p-4 text-white"
      >
        <PiSparkle className="text-5xl" />
        <h1 className="text-5xl font-bold">Pampering Grooming Services</h1>
        <p className="text-gray-100 text-xl font-light">
          Treat your pet to the ultimate spa experience with our convenient
          grooming appointments, ensuring they look and feel their best.
        </p>
      </motion.div>
    </div>
  );
}

export default Features;
