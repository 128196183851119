import React from "react";
import Footer from "../components/Footer";
import logo from "../assets/images/logo.png";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function DeleteAccount() {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isLoading },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const values = { ...data };
    axios
      .post(
        "https://pet-api.nour-x-core.com/identification/authentication/grant/email",
        values
      )
      .then((res) => {
        if (res.data.token) {
          axios
            .request({
              url: "https://pet-api.nour-x-core.com/user/private",
              data: values,
              method: "delete",
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              toast("Account Deleted Successfully");
              navigate("/");
            })
            .catch((e) => {});
        }
      })
      .catch((e) => {});
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 p-5">
        <div className="p-5 bg-[#b5c4e26f] rounded-full flex justify-center md:justify-start">
          <img src={logo} className="w-[200px]" alt="Vetsee Logo" />
        </div>
        <div className="flex flex-col gap-5 justify-center items-center text-center mt-5">
          <h1 className="text-3xl font-bold">We're sad to see you go</h1>
          <h2 className="text-md text-gray-600">
            If you made up your mind and want to proceed with account deletion
            please enter your email and password below
          </h2>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col justify-center gap-2 w-full max-w-[60%]"
          >
            <input
              className="rounded-full p-3"
              placeholder="Email"
              name="email"
              {...register("email", {
                required: true,
                pattern:
                  /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/,
              })}
            />
            {errors.email && (
              <span className="text-red-500">Email is required</span>
            )}
            <input
              placeholder="Password"
              className="rounded-full p-3"
              type="password"
              {...register("password", { required: true, minLength: 6 })}
            />
            {errors.password && (
              <span className="text-red-500">Password is required</span>
            )}
            <div className="w-full">
              <motion.button
                whileTap={{
                  scale: 0.95,
                }}
                type="submit"
                className="bg-[#b5c4e26f] p-3 rounded-full w-fit font-bold"
                disabled={!isValid && isLoading}
              >
                Submit
              </motion.button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DeleteAccount;
