import React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Features from "../components/Features";
import JoinNow from "../components/JoinNow";

function Home() {
  return (
    <div className="flex flex-col w-full justify-center">
      <Hero />
      <Features />
      <JoinNow />
      <Footer />
    </div>
  );
}

export default Home;
