import React, { useEffect } from "react";
import iphone from "../assets/images/iphone.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function JoinNow() {
  const controls = useAnimation();

  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls]);

  const imageVariant = {
    hidden: {
      opacity: 0,
      y: 100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div ref={ref} className="flex flex-col justify-center items-center p-20">
      <span className="text-6xl font-bold text-center">
        Join Vetsee Community Now
      </span>
      <motion.img
        initial="hidden"
        animate={controls}
        variants={imageVariant}
        src={iphone}
        alt="iphone screen"
        className="max-h-[800px]"
      />
      <motion.button
        onClick={() => {
          document
            .getElementById("hero")
            .scrollIntoView({ behavior: "smooth" });
        }}
        whileHover={{
          y: -4,
        }}
        whileTap={{
          scale: 1.08,
        }}
        className="bg-orange-500 px-5 py-3 rounded-full text-white shadow-md font-bold"
      >
        Join Now
      </motion.button>
    </div>
  );
}

export default JoinNow;
